import safeGet from 'just-safe-get'
import { TEMP_SCOPE_PREFIX } from '../components/permissions-view/helpers'
import store from '@/store'
import { isUnifiedCommerce } from './index'
import { getExportHistoryPermissions } from './permissions/export-history'
import { useAppConfigStore } from '@/store/app-config'

export const allPermissionsGranted = ['all', 'admin', 'support']
export function permissionsConfigStaff(vm = { $t: (f) => f }) {
  return [
    {
      name: 'transactions',
      scope: 'staff:staff:transactions',
      description: vm.$t('pages.staff.form.scopes.transactions.description'),
      title: vm.$t('pages.staff.form.scopes.transactions.title'),
      children: [
        {
          name: 'create',
          scope: 'staff:staff:transactions:create',
          description: vm.$t(
            'pages.staff.form.scopes.transactions:create.description'
          ),
          title: vm.$t('pages.staff.form.scopes.actions.create')
        },
        {
          name: 'customer',
          scope: 'staff:staff:transactions:customer',
          description: vm.$t(
            'pages.staff.form.scopes.transactions:customer.description'
          ),
          title: vm.$t('pages.staff.form.scopes.transactions:customer.title')
        },
        {
          name: 'refund',
          scope: 'staff:staff:transactions:refund',
          description: vm.$t(
            'pages.staff.form.scopes.transactions:refund.description'
          ),
          title: vm.$t('pages.staff.form.scopes.transactions:refund.title')
        },
        {
          name: 'cancel',
          scope: 'staff:staff:transactions:cancel',
          description: vm.$t(
            'pages.staff.form.scopes.transactions:cancel.description'
          ),
          title: vm.$t('pages.staff.form.scopes.transactions:cancel.title')
        },
        {
          name: 'cancel:row',
          scope: 'staff:staff:transactions:cancel:row',
          description: vm.$t(
            'pages.staff.form.scopes.transactions:cancel:row.description'
          ),
          title: vm.$t('pages.staff.form.scopes.transactions:cancel:row.title')
        },
        {
          name: 'prices',
          scope: 'staff:staff:transactions:prices',
          description: vm.$t(
            'pages.staff.form.scopes.transactions:prices.description'
          ),
          title: vm.$t('pages.staff.form.scopes.transactions:prices.title')
        },
        {
          name: 'qty:positive',
          scope: 'staff:staff:transactions:qty:positive',
          description: vm.$t(
            'pages.staff.form.scopes.transactions:qty:positive.description'
          ),
          title: vm.$t(
            'pages.staff.form.scopes.transactions:qty:positive.title'
          )
        },
        {
          name: 'cart:products:services',
          scope: 'staff:staff:transactions:cart:products:services',
          description: vm.$t(
            'pages.staff.form.scopes.transactions:cart:products:services.description'
          ),
          title: vm.$t(
            'pages.staff.form.scopes.transactions:cart:products:services.title'
          )
        },
        {
          name: 'vouchers',
          scope: 'staff:staff:transactions:vouchers',
          description: vm.$t(
            'pages.staff.form.scopes.transactions:vouchers.description'
          ),
          title: vm.$t('pages.staff.form.scopes.transactions:vouchers.title')
        },
        {
          name: 'qty:negative',
          scope: 'staff:staff:transactions:qty:negative',
          description: vm.$t(
            'pages.staff.form.scopes.transactions:qty:negative.description'
          ),
          title: vm.$t(
            'pages.staff.form.scopes.transactions:qty:negative.title'
          )
        },
        {
          name: 'print',
          scope: 'staff:staff:transactions:print',
          description: vm.$t(
            'pages.staff.form.scopes.transactions:print.description'
          ),
          title: vm.$t('pages.staff.form.scopes.transactions:print.title')
        },
        {
          name: 'history:print',
          scope: 'staff:staff:transactions:history:print',
          description: vm.$t(
            'pages.staff.form.scopes.transactions:history:print.description'
          ),
          title: vm.$t(
            'pages.staff.form.scopes.transactions:history:print.title'
          )
        },
        {
          name: 'history:email',
          scope: 'staff:staff:transactions:history:email',
          description: vm.$t(
            'pages.staff.form.scopes.transactions:history:email.description'
          ),
          title: vm.$t(
            'pages.staff.form.scopes.transactions:history:email.title'
          )
        }
      ]
    },
    {
      name: 'discounts',
      scope: 'staff:staff:transactions:discounts',
      description: vm.$t(
        'pages.staff.form.scopes.transactions:discounts.description'
      ),
      title: vm.$t('pages.staff.form.scopes.transactions:discounts.title'),
      children: [
        {
          name: 'discounts:set_percent',
          scope: 'staff:staff:transactions:discounts:set:percent',
          description: vm.$t(
            'pages.staff.form.scopes.transactions:discounts.set_percent.description'
          ),
          title: vm.$t(
            'pages.staff.form.scopes.transactions:discounts.set_percent.title'
          )
        },
        {
          name: 'discounts:set_value',
          scope: 'staff:staff:transactions:discounts:set:value',
          description: vm.$t(
            'pages.staff.form.scopes.transactions:discounts:set_value.description'
          ),
          title: vm.$t(
            'pages.staff.form.scopes.transactions:discounts:set_value.title'
          )
        },
        {
          name: 'discounts:apply_percent',
          scope: 'staff:staff:transactions:discounts:apply:percent',
          description: vm.$t(
            'pages.staff.form.scopes.transactions:discounts:apply_percent.description'
          ),
          title: vm.$t(
            'pages.staff.form.scopes.transactions:discounts:apply_percent.title'
          )
        },
        {
          name: 'discounts:apply_value',
          scope: 'staff:staff:transactions:discounts:apply:value',
          description: vm.$t(
            'pages.staff.form.scopes.transactions:discounts:apply_value.description'
          ),
          title: vm.$t(
            'pages.staff.form.scopes.transactions:discounts:apply_value.title'
          )
        }
      ]
    },
    {
      name: 'payments',
      scope: 'staff:staff:payments',
      description: vm.$t('pages.staff.form.scopes.payments.description'),
      title: vm.$t('pages.staff.form.scopes.payments.title'),
      children: [
        {
          name: 'cash',
          scope: 'staff:staff:payments:cash',
          description: vm.$t(
            'pages.staff.form.scopes.payments:cash.description'
          ),
          title: vm.$t('pages.staff.form.scopes.payments:cash.title')
        },
        {
          name: 'invoice',
          scope: 'staff:staff:payments:invoice',
          description: vm.$t(
            'pages.staff.form.scopes.payments:invoice.description'
          ),
          title: vm.$t('pages.staff.form.scopes.payments:invoice.title')
        },
        {
          name: 'voucher',
          scope: 'staff:staff:payments:voucher',
          description: vm.$t(
            'pages.staff.form.scopes.payments:voucher.description'
          ),
          title: vm.$t('pages.staff.form.scopes.payments:voucher.title')
        },
        {
          name: 'card',
          scope: 'staff:staff:payments:card',
          description: vm.$t(
            'pages.staff.form.scopes.payments:card.description'
          ),
          title: vm.$t('pages.staff.form.scopes.payments:card.title')
        },
        {
          name: 'split',
          scope: 'staff:staff:payments:split',
          description: vm.$t(
            'pages.staff.form.scopes.payments:split.description'
          ),
          title: vm.$t('pages.staff.form.scopes.payments:split.title')
        },
        {
          name: 'split:cash',
          scope: 'staff:staff:payments:split:cash',
          description: vm.$t(
            'pages.staff.form.scopes.payments:split:cash.description'
          ),
          title: vm.$t('pages.staff.form.scopes.payments:split:cash.title')
        },
        {
          name: 'split:invoice',
          scope: 'staff:staff:payments:split:invoice',
          description: vm.$t(
            'pages.staff.form.scopes.payments:split:invoice.description'
          ),
          title: vm.$t('pages.staff.form.scopes.payments:split:invoice.title')
        },
        {
          name: 'split:voucher',
          scope: 'staff:staff:payments:split:voucher',
          description: vm.$t(
            'pages.staff.form.scopes.payments:split:voucher.description'
          ),
          title: vm.$t('pages.staff.form.scopes.payments:split:voucher.title')
        },
        {
          name: 'split:card',
          scope: 'staff:staff:payments:split:card',
          description: vm.$t(
            'pages.staff.form.scopes.payments:split:card.description'
          ),
          title: vm.$t('pages.staff.form.scopes.payments:split:card.title')
        }
      ]
    },
    {
      name: 'delivery_notes',
      scope: 'staff:staff:delivery_notes',
      description: vm.$t('pages.staff.form.scopes.delivery_notes.description'),
      title: vm.$t('pages.staff.form.scopes.delivery_notes.title'),
      children: [
        {
          name: 'create',
          scope: 'staff:staff:delivery_notes:create',
          description: vm.$t(
            'pages.staff.form.scopes.delivery_notes:create.description'
          ),
          title: vm.$t('pages.staff.form.scopes.actions.create')
        }
      ]
    },
    {
      name: 'carts',
      scope: 'staff:staff:carts',
      description: vm.$t('pages.staff.form.scopes.carts.description'),
      title: vm.$t('pages.staff.form.scopes.carts.title'),
      children: [
        {
          name: 'read',
          scope: 'reports_statistics:staff:read',
          title: vm.$t('common.actions.read')
        },
        {
          name: 'export',
          scope: 'reports_statistics:staff:export',
          title: vm.$t('common.actions.export')
        },
        {
          name: 'clear',
          scope: 'staff:staff:cart:clear',
          title: vm.$t('pages.staff.form.scopes.carts:clear.description')
        }
      ]
    },
    {
      name: 'customers',
      scope: 'reports_statistics:customers',
      title: vm.$t('pages.reports.statistics.customers.customers'),
      children: [
        {
          name: 'create',
          scope: 'staff:staff:carts:create',
          description: vm.$t(
            'pages.staff.form.scopes.carts:create.description'
          ),
          title: vm.$t('pages.staff.form.scopes.actions.create')
        },
        {
          name: 'update',
          scope: 'staff:staff:carts:update',
          description: vm.$t(
            'pages.staff.form.scopes.carts:update.description'
          ),
          title: vm.$t('pages.staff.form.scopes.actions.update')
        },
        {
          name: 'read',
          scope: 'staff:staff:carts:read',
          description: vm.$t('pages.staff.form.scopes.carts:read.description'),
          title: vm.$t('pages.staff.form.scopes.actions.read')
        },
        {
          name: 'delete',
          scope: 'staff:staff:carts:delete',
          description: vm.$t(
            'pages.staff.form.scopes.carts:delete.description'
          ),
          title: vm.$t('pages.staff.form.scopes.actions.delete')
        },
        {
          name: 'put_back',
          scope: 'staff:staff:carts:put_back',
          title: vm.$t('pages.staff.form.scopes.carts:put_back.description')
        }
      ]
    },
    {
      name: 'registers',
      scope: 'staff:staff:registers',
      description: vm.$t('pages.staff.form.scopes.registers.description'),
      title: vm.$t('pages.staff.form.scopes.registers.title'),
      children: [
        {
          name: 'open',
          scope: 'staff:staff:registers:open',
          description: vm.$t(
            'pages.staff.form.scopes.registers:open.description'
          ),
          title: vm.$t('pages.staff.form.scopes.registers:open.title')
        }
      ]
    },
    {
      name: 'settings',
      scope: 'staff:staff:settings',
      description: vm.$t('pages.staff.form.scopes.settings.description'),
      title: vm.$t('pages.staff.form.scopes.settings.title'),
      children: [
        {
          name: 'read',
          scope: 'staff:staff:settings:read',
          description: vm.$t(
            'pages.staff.form.scopes.settings:read.description'
          ),
          title: vm.$t('pages.staff.form.scopes.actions.read')
        },
        {
          name: 'printer',
          scope: 'staff:staff:settings:printer',
          description: vm.$t(
            'pages.staff.form.scopes.settings:printer.description'
          ),
          title: vm.$t('pages.staff.form.scopes.settings:printer.title')
        },
        {
          name: 'printer:create',
          scope: 'staff:staff:settings:printer:create',
          description: vm.$t(
            'pages.staff.form.scopes.settings:printer:create.description'
          ),
          title: vm.$t('pages.staff.form.scopes.actions.create')
        },
        {
          name: 'printer:update',
          scope: 'staff:staff:settings:printer:update',
          description: vm.$t(
            'pages.staff.form.scopes.settings:printer:update.description'
          ),
          title: vm.$t('pages.staff.form.scopes.actions.update')
        },
        {
          name: 'printer:read',
          scope: 'staff:staff:settings:printer:read',
          description: vm.$t(
            'pages.staff.form.scopes.settings:printer:read.description'
          ),
          title: vm.$t('pages.staff.form.scopes.actions.read')
        },
        {
          name: 'printer:delete',
          scope: 'staff:staff:settings:printer:delete',
          description: vm.$t(
            'pages.staff.form.scopes.settings:printer:delete.description'
          ),
          title: vm.$t('pages.staff.form.scopes.actions.delete')
        },
        {
          name: 'printer:drawer:open',
          scope: 'staff:staff:settings:printer:drawer:open',
          description: vm.$t(
            'pages.staff.form.scopes.settings:printer:drawer:open.description'
          ),
          title: vm.$t(
            'pages.staff.form.scopes.drawer:open:finishpaymentview.title'
          )
        },
        {
          name: 'terminal',
          scope: 'staff:staff:settings:terminal',
          description: vm.$t(
            'pages.staff.form.scopes.settings:terminal.description'
          ),
          title: vm.$t('pages.staff.form.scopes.settings:terminal.title')
        },
        {
          name: 'terminal:create',
          scope: 'staff:staff:settings:terminal:create',
          description: vm.$t(
            'pages.staff.form.scopes.settings:terminal:create.description'
          ),
          title: vm.$t('pages.staff.form.scopes.actions.create')
        },
        {
          name: 'terminal:update',
          scope: 'staff:staff:settings:terminal:update',
          description: vm.$t(
            'pages.staff.form.scopes.settings:terminal:update.description'
          ),
          title: vm.$t('pages.staff.form.scopes.actions.update')
        },
        {
          name: 'terminal:read',
          scope: 'staff:staff:settings:terminal:read',
          description: vm.$t(
            'pages.staff.form.scopes.settings:terminal:read.description'
          ),
          title: vm.$t('pages.staff.form.scopes.actions.read')
        },
        {
          name: 'terminal:delete',
          scope: 'staff:staff:settings:terminal:delete',
          description: vm.$t(
            'pages.staff.form.scopes.settings:terminal:delete.description'
          ),
          title: vm.$t('pages.staff.form.scopes.actions.delete')
        },
        {
          name: 'fleurop',
          scope: 'staff:staff:settings:fleurop',
          description: vm.$t(
            'pages.staff.form.scopes.settings:fleurop.description'
          ),
          title: vm.$t('pages.staff.form.scopes.settings:fleurop.title')
        },
        {
          name: 'dashboard',
          scope: 'staff:staff:settings:dashboard',
          description: vm.$t(
            'pages.staff.form.scopes.settings:dashboard.description'
          ),
          title: vm.$t('pages.staff.form.scopes.settings:dashboard.title')
        },
        {
          name: 'database',
          scope: 'staff:staff:settings:database',
          description: vm.$t(
            'pages.staff.form.scopes.settings:database.description'
          ),
          title: vm.$t('pages.staff.form.scopes.settings:database.title')
        },
        {
          name: 'history',
          scope: 'staff:staff:settings:history',
          description: vm.$t(
            'pages.staff.form.scopes.settings:history.description'
          ),
          title: vm.$t('pages.staff.form.scopes.settings:history.title')
        }
      ]
    },
    {
      name: 'products',
      scope: 'staff:staff:products',
      description: vm.$t('pages.staff.form.scopes.products.description'),
      title: vm.$t('pages.staff.form.scopes.products.title'),
      children: [
        {
          name: 'create',
          scope: 'staff:staff:products:create',
          description: vm.$t(
            'pages.staff.form.scopes.products:create.description'
          ),
          title: vm.$t('pages.staff.form.scopes.actions.create')
        },
        {
          name: 'update',
          scope: 'staff:staff:products:update',
          description: vm.$t(
            'pages.staff.form.scopes.products:update.description'
          ),
          title: vm.$t('pages.staff.form.scopes.actions.update')
        },
        {
          name: 'delete',
          scope: 'staff:staff:products:delete',
          description: vm.$t(
            'pages.staff.form.scopes.products:delete.description'
          ),
          title: vm.$t('pages.staff.form.scopes.actions.delete')
        },
        {
          name: 'purchase_price',
          scope: 'staff:staff:products:purchase_price',
          description: vm.$t(
            'pages.staff.form.scopes.products:purchase_price.description'
          ),
          title: vm.$t('pages.staff.form.scopes.products:purchase_price.title')
        }
      ]
    },
    {
      name: 'customers',
      scope: 'staff:staff:customers',
      description: vm.$t('pages.staff.form.scopes.customers.description'),
      title: vm.$t('pages.staff.form.scopes.customers.title'),
      children: [
        {
          name: 'create',
          scope: 'staff:staff:customers:create',
          description: vm.$t(
            'pages.staff.form.scopes.customers:create.description'
          ),
          title: vm.$t('pages.staff.form.scopes.actions.create')
        },
        {
          name: 'update',
          scope: 'staff:staff:customers:update',
          description: vm.$t(
            'pages.staff.form.scopes.customers:update.description'
          ),
          title: vm.$t('pages.staff.form.scopes.actions.update')
        },
        {
          name: 'read',
          scope: 'staff:staff:customers:read',
          description: vm.$t(
            'pages.staff.form.scopes.customers:read.description'
          ),
          title: vm.$t('pages.staff.form.scopes.actions.read')
        },
        {
          name: 'delete',
          scope: 'staff:staff:customers:delete',
          description: vm.$t(
            'pages.staff.form.scopes.customers:delete.description'
          ),
          title: vm.$t('pages.staff.form.scopes.actions.delete')
        }
      ]
    },
    {
      name: 'vouchers',
      scope: 'staff:staff:vouchers',
      description: vm.$t('pages.staff.form.scopes.vouchers.description'),
      title: vm.$t('pages.staff.form.scopes.vouchers.title'),
      children: [
        {
          name: 'create',
          scope: 'staff:staff:vouchers:create',
          description: vm.$t(
            'pages.staff.form.scopes.vouchers:create.description'
          ),
          title: vm.$t('pages.staff.form.scopes.actions.create')
        },
        {
          name: 'update',
          scope: 'staff:staff:vouchers:update',
          description: vm.$t(
            'pages.staff.form.scopes.vouchers:update.description'
          ),
          title: vm.$t('pages.staff.form.scopes.actions.update')
        },
        {
          name: 'read',
          scope: 'staff:staff:vouchers:read',
          description: vm.$t(
            'pages.staff.form.scopes.vouchers:read.description'
          ),
          title: vm.$t('pages.staff.form.scopes.actions.read')
        },
        {
          name: 'delete',
          scope: 'staff:staff:vouchers:delete',
          description: vm.$t(
            'pages.staff.form.scopes.vouchers:delete.description'
          ),
          title: vm.$t('pages.staff.form.scopes.actions.delete')
        }
      ]
    },
    {
      name: 'balances',
      scope: 'staff:staff:balances',
      description: vm.$t('pages.staff.form.scopes.balances.description'),
      title: vm.$t('pages.staff.form.scopes.balances.title'),
      children: [
        {
          name: 'x',
          scope: 'staff:staff:balances:x',
          description: vm.$t('pages.staff.form.scopes.balances:x.description'),
          title: vm.$t('pages.staff.form.scopes.balances:x.title')
        },
        {
          name: 'z',
          scope: 'staff:staff:balances:z',
          description: vm.$t('pages.staff.form.scopes.balances:z.description'),
          title: vm.$t('pages.staff.form.scopes.balances:z.title')
        },
        {
          name: 'x:print',
          scope: 'staff:staff:balances:x:print',
          description: vm.$t(
            'pages.staff.form.scopes.balances:x:print.description'
          ),
          title: vm.$t('pages.staff.form.scopes.balances:x:print.title')
        },
        {
          name: 'z:print',
          scope: 'staff:staff:balances:z:print',
          description: vm.$t(
            'pages.staff.form.scopes.balances:z:print.description'
          ),
          title: vm.$t('pages.staff.form.scopes.balances:z:print.title')
        },
        {
          name: 'z:history:print',
          scope: 'staff:staff:balances:z:history:print',
          description: vm.$t(
            'pages.staff.form.scopes.balances:z:history:print.description'
          ),
          title: vm.$t('pages.staff.form.scopes.balances:z:history:print.title')
        },
        {
          name: 'balance',
          scope: 'staff:staff:balance',
          description: vm.$t('pages.staff.form.scopes.balance.title'),
          title: vm.$t('pages.staff.form.scopes.balance.title')
        }
      ]
    },
    {
      name: 'devices',
      scope: 'staff:staff:devices',
      description: vm.$t('pages.staff.form.scopes.devices.description'),
      title: vm.$t('pages.staff.form.scopes.devices.title'),
      children: [
        {
          name: 'bind',
          scope: 'staff:staff:devices:bind',
          description: vm.$t(
            'pages.staff.form.scopes.devices:bind.description'
          ),
          title: vm.$t('pages.staff.form.scopes.devices:bind.title')
        },
        {
          name: 'unbind',
          scope: 'staff:staff:devices:unbind',
          description: vm.$t(
            'pages.staff.form.scopes.devices:unbind.description'
          ),
          title: vm.$t('pages.staff.form.scopes.devices:unbind.title')
        }
      ]
    },
    {
      name: 'licenses',
      scope: 'staff:staff:licenses',
      description: vm.$t('pages.staff.form.scopes.licenses.description'),
      title: vm.$t('pages.staff.form.scopes.licenses.title'),
      children: [
        {
          name: 'create',
          scope: 'staff:staff:licenses:create',
          description: vm.$t(
            'pages.staff.form.scopes.licenses:create.description'
          ),
          title: vm.$t('pages.staff.form.scopes.licenses:create.title')
        },
        {
          name: 'delete',
          scope: 'staff:staff:licenses:delete',
          description: vm.$t(
            'pages.staff.form.scopes.licenses:delete.description'
          ),
          title: vm.$t('pages.staff.form.scopes.licenses:delete.title')
        }
      ]
    },
    {
      name: 'processes',
      scope: 'staff:staff:processes',
      description: vm.$t('pages.staff.form.scopes.processes.description'),
      title: vm.$t('pages.staff.form.scopes.processes.title'),
      children: [
        {
          name: 'all_staff',
          scope: 'staff:staff:processes:all_staff',
          description: vm.$t(
            'pages.staff.form.scopes.processes:all_staff.description'
          ),
          title: vm.$t('pages.staff.form.scopes.processes:all_staff.title')
        },
        {
          name: 'all_locations',
          scope: 'staff:staff:processes:all_locations',
          description: vm.$t(
            'pages.staff.form.scopes.processes:all_locations.description'
          ),
          title: vm.$t('pages.staff.form.scopes.processes:all_locations.title')
        }
      ]
    },
    ...(store.getters['Auth/isGastro']
      ? [
          {
            name: 'gastronomy',
            scope: 'staff:staff:orders',
            title: vm.$t('pages.staff.form.scopes.gastronomy.title'),
            children: [
              {
                name: 'others_locked_modify',
                scope: 'staff:staff:orders:others:locked:modify',
                description: vm.$t(
                  'pages.staff.form.scopes.gastronomy:others_locked_modify.description'
                )
              },
              {
                name: 'preview_print',
                scope: 'staff:staff:orders:preview:print',
                description: vm.$t(
                  'pages.staff.form.scopes.gastronomy:preview_print.description'
                )
              }
            ]
          }
        ]
      : []),
    {
      name: 'wallets',
      scope: 'staff:staff:wallets',
      title: vm.$t('pages.staff.form.scopes.wallets.title'),
      children: [
        {
          name: 'create',
          scope: 'staff:staff:wallets:create',
          title: vm.$t('pages.staff.form.scopes.wallets:create.title')
        },
        {
          name: 'update',
          scope: 'staff:staff:wallets:update',
          title: vm.$t('pages.staff.form.scopes.wallets:update.title')
        },
        {
          name: 'transfer',
          scope: 'staff:staff:wallets:transfer',
          title: vm.$t('pages.staff.form.scopes.wallets:transfer.title')
        },
        {
          name: 'discrepancy',
          scope: 'staff:staff:wallets:discrepancy',
          title: vm.$t('pages.staff.form.scopes.wallets:discrepancy.title')
        }
      ]
    },
    {
      name: 'roles',
      scope: `${TEMP_SCOPE_PREFIX}:roles`,
      title: vm.$t('pages.staff.form.scopes.roles.title'),
      children: [
        {
          name: 'cashier',
          scope: 'staff:staff:cashier',
          description: vm.$t('pages.staff.form.scopes.cashier.description'),
          title: vm.$t('pages.staff.form.scopes.cashier.title')
        },
        {
          name: 'salesman',
          scope: 'staff:staff:salesman',
          description: vm.$t('pages.staff.form.scopes.salesman.description'),
          title: vm.$t('pages.staff.form.scopes.salesman.title')
        }
      ]
    },
    {
      name: 'miscellaneous',
      scope: `${TEMP_SCOPE_PREFIX}:miscellaneous`,
      title: vm.$t('pages.staff.form.scopes.miscellaneous.title'),
      children: [
        {
          name: 'journals',
          scope: 'staff:staff:analytics:journals',
          description: vm.$t(
            'pages.staff.form.scopes.analytics:journals.description'
          ),
          title: vm.$t('pages.staff.form.scopes.analytics:journals.title')
        },
        {
          name: 'receipts',
          scope: 'staff:staff:receipts',
          description: vm.$t('pages.staff.form.scopes.receipts.description'),
          title: vm.$t('pages.staff.form.scopes.receipts.title')
        },
        {
          name: 'login',
          scope: 'staff:staff:login',
          description: vm.$t('pages.staff.form.scopes.login.description'),
          title: vm.$t('pages.staff.form.scopes.login.title')
        },
        {
          name: 'logout',
          scope: 'staff:staff:logout',
          description: vm.$t('pages.staff.form.scopes.logout.description'),
          title: vm.$t('pages.staff.form.scopes.logout.title')
        },
        {
          name: 'drawer:open:finishpaymentview',
          scope: 'staff:staff:drawer:open:finishpaymentview',
          description: vm.$t(
            'pages.staff.form.scopes.drawer:open:finishpaymentview.description'
          ),
          title: vm.$t(
            'pages.staff.form.scopes.drawer:open:finishpaymentview.title'
          )
        },
        {
          name: 'history',
          scope: 'staff:staff:history',
          description: vm.$t('pages.staff.form.scopes.history.description'),
          title: vm.$t('pages.staff.form.scopes.history.title')
        },
        {
          name: 'stocks',
          scope: 'staff:staff:stocks',
          description: vm.$t('pages.staff.form.scopes.stocks.description'),
          title: vm.$t('pages.staff.form.scopes.stocks.title')
        },
        {
          name: 'expenses',
          scope: 'staff:staff:expenses',
          description: vm.$t('pages.staff.form.scopes.expenses.description'),
          title: vm.$t('pages.staff.form.scopes.expenses.title')
        },
        {
          name: 'down_payments',
          scope: 'staff:staff:down_payments',
          description: vm.$t(
            'pages.staff.form.scopes.down_payments.description'
          ),
          title: vm.$t('pages.staff.form.scopes.down_payments.title')
        },
        {
          name: 'logs',
          scope: 'staff:staff:logs',
          description: vm.$t('pages.staff.form.scopes.logs.description'),
          title: vm.$t('pages.staff.form.scopes.logs.title')
        },
        {
          name: 'deposits',
          scope: 'staff:staff:deposits',
          description: vm.$t('pages.staff.form.scopes.deposits.description'),
          title: vm.$t('pages.staff.form.scopes.deposits.title')
        },
        {
          name: 'favourites',
          scope: 'staff:staff:favourites',
          description: vm.$t('pages.staff.form.scopes.favourites.description'),
          title: vm.$t('pages.staff.form.scopes.favourites.title')
        }
      ]
    }
  ]
}
export function permissionsConfigUser(vm = { $t: (f) => f }) {
  const appConfigStore = useAppConfigStore()
  return {
    reports_financial_accounting: [
      {
        name: 'transactions',
        scope: 'reports_financial_accounting:transactions',
        title: vm.$t('pages.transactions.title'),
        children: [
          {
            name: 'read',
            scope: 'reports_financial_accounting:transactions:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'export',
            scope: 'reports_financial_accounting:transactions:export',
            title: vm.$t('common.actions.export')
          }
        ]
      },
      {
        name: 'balances',
        scope: 'reports_financial_accounting:balances',
        title: vm.$t('pages.balances.title'),
        children: [
          {
            name: 'read',
            scope: 'reports_financial_accounting:balances:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'export',
            scope: 'reports_financial_accounting:balances:export',
            title: vm.$t('common.actions.export')
          }
        ]
      },
      ...(safeGet(
        appConfigStore.featureConfig,
        'reports.financial_accounting.cash_book',
        false
      )
        ? [
            {
              name: 'cash_book',
              scope: 'reports_financial_accounting:cash_book',
              title: vm.$t('pages.cash_book.title'),
              children: [
                {
                  name: 'read',
                  scope: 'reports_financial_accounting:cash_book:read',
                  title: vm.$t('common.actions.read')
                },
                {
                  name: 'export',
                  scope: 'reports_financial_accounting:cash_book:export',
                  title: vm.$t('common.actions.export')
                }
              ]
            }
          ]
        : []),
      {
        name: 'returns',
        scope: 'reports_financial_accounting:returns',
        title: vm.$t('reports.financial_accounting.returns'),
        children: [
          {
            name: 'read',
            scope: 'reports_financial_accounting:returns:read',
            title: vm.$t('common.actions.read')
          }
        ]
      },
      {
        name: 'payment_options',
        scope: 'reports_financial_accounting:payment_options',
        title: vm.$t('pages.payment_options.title'),
        children: [
          {
            name: 'read',
            scope: 'reports_financial_accounting:payment_options:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'export',
            scope: 'reports_financial_accounting:payment_options:export',
            title: vm.$t('common.actions.export')
          }
        ]
      },
      {
        name: 'payments',
        scope: 'reports_financial_accounting:payments',
        title: vm.$t('pages.payments.title'),
        children: [
          {
            name: 'read',
            scope: 'reports_financial_accounting:payments:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'export',
            scope: 'reports_financial_accounting:payments:export',
            title: vm.$t('common.actions.export')
          }
        ]
      },
      {
        name: 'vat',
        scope: 'reports_financial_accounting:vat',
        title: vm.$t('pages.vat.title'),
        children: [
          {
            name: 'read',
            scope: 'reports_financial_accounting:vat:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'export',
            scope: 'reports_financial_accounting:vat:export',
            title: vm.$t('common.actions.export')
          }
        ]
      },
      {
        name: 'counting_protocols',
        scope: 'reports_financial_accounting:counting_protocols',
        title: vm.$t('pages.counting_protocols.title'),
        children: [
          {
            name: 'read',
            scope: 'reports_financial_accounting:counting_protocols:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'export',
            scope: 'reports_financial_accounting:counting_protocols:export',
            title: vm.$t('common.actions.export')
          }
        ]
      }
    ],
    reports_statistics: [
      {
        name: 'revenues',
        scope: 'reports_statistics:revenues',
        title: vm.$t('pages.reports.statistics.revenues.revenues'),
        children: [
          {
            name: 'read',
            scope: 'reports_statistics:revenues:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'export',
            scope: 'reports_statistics:revenues:export',
            title: vm.$t('common.actions.export')
          }
        ]
      },
      {
        name: 'products',
        scope: 'reports_statistics:products',
        title: vm.$t('pages.reports.statistics.products.products'),
        children: [
          {
            name: 'read',
            scope: 'reports_statistics:products:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'export',
            scope: 'reports_statistics:products:export',
            title: vm.$t('common.actions.export')
          }
        ]
      },
      {
        name: 'product_groups',
        scope: 'reports_statistics:product_groups',
        title: vm.$t('pages.reports.statistics.productGroups.productGroups'),
        children: [
          {
            name: 'read',
            scope: 'reports_statistics:product_groups:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'export',
            scope: 'reports_statistics:product_groups:export',
            title: vm.$t('common.actions.export')
          }
        ]
      },
      {
        name: 'staff',
        scope: 'reports_statistics:staff',
        title: vm.$t('pages.reports.statistics.staff.staff'),
        children: [
          {
            name: 'read',
            scope: 'reports_statistics:staff:read',
            title: vm.$t('common.actions.read')
          }
        ]
      },
      {
        name: 'customers',
        scope: 'reports_statistics:customers',
        title: vm.$t('pages.reports.statistics.customers.customers'),
        children: [
          {
            name: 'read',
            scope: 'reports_statistics:customers:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'export',
            scope: 'reports_statistics:customers:export',
            title: vm.$t('common.actions.export')
          }
        ]
      },
      {
        name: 'vouchers',
        scope: 'reports_statistics:vouchers',
        title: vm.$t('pages.reports.statistics.vouchers.vouchers'),
        children: [
          {
            name: 'read',
            scope: 'reports_statistics:vouchers:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'export',
            scope: 'reports_statistics:vouchers:export',
            title: vm.$t('common.actions.export')
          }
        ]
      },
      {
        name: 'discounts',
        scope: 'reports_statistics:discounts',
        title: vm.$t('pages.reports.statistics.discounts.discounts'),
        children: [
          {
            name: 'read',
            scope: 'reports_statistics:discounts:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'export',
            scope: 'reports_statistics:discounts:export',
            title: vm.$t('common.actions.export')
          }
        ]
      },
      {
        name: 'stocks',
        scope: 'reports_statistics:stocks',
        title: vm.$t('pages.reports.statistics.stocks.stocks'),
        children: [
          {
            name: 'read',
            scope: 'reports_statistics:stocks:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'export',
            scope: 'reports_statistics:stocks:export',
            title: vm.$t('common.actions.export')
          }
        ]
      },
      {
        name: 'sold_cart_items',
        scope: 'reports_statistics:sold_cart_items',
        title: vm.$t('pages.reports.statistics.sold_cart_items.title'),
        children: [
          {
            name: 'read',
            scope: 'reports_statistics:sold_cart_items:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'export',
            scope: 'reports_statistics:sold_cart_items:export',
            title: vm.$t('common.actions.export')
          }
        ]
      }
    ],
    reports_exports_manager: [
      {
        name: 'financial',
        scope: 'reports_exports_manager:financial',
        title: vm.$t(
          'pages.reports.exports.overview.quick.tabs.financial.title'
        ),
        children: [
          {
            name: 'gobd',
            scope: 'reports_exports_manager:financial:gobd',
            title: vm.$t(
              'pages.reports.exports.overview.quick.tabs.financial.gobd.title'
            )
          },
          {
            name: 'datev',
            scope: 'reports_exports_manager:financial:datev',
            title: vm.$t(
              'pages.reports.exports.overview.quick.tabs.financial.datev.title'
            )
          },
          {
            name: 'safe_logs',
            scope: 'reports_exports_manager:financial:safe_logs',
            title: vm.$t(
              'pages.reports.exports.overview.quick.tabs.financial.safes_logs.title'
            )
          }
        ]
      }
    ],
    ...(isUnifiedCommerce()
      ? {
          orders: [
            {
              name: 'reports_orders',
              scope: 'reports_orders',
              title: vm.$t('pages.reports_orders.title'),
              children: [
                {
                  name: 'read',
                  scope: 'reports_orders:read',
                  title: vm.$t('common.actions.read')
                },
                {
                  name: 'export',
                  scope: 'reports_orders:export',
                  title: vm.$t('common.actions.export')
                },
                {
                  name: 'mutate',
                  scope: 'reports_orders:mutate',
                  title: vm.$t('pages.reports.orders.mutate')
                }
              ]
            },
            {
              name: 'orders_transactions',
              scope: 'orders_transactions',
              title: vm.$t('pages.orders_transactions.title'),
              children: [
                {
                  name: 'read',
                  scope: 'orders_transactions:read',
                  title: vm.$t('common.actions.read')
                },
                {
                  name: 'export',
                  scope: 'orders_transactions:export',
                  title: vm.$t('common.actions.export')
                }
              ]
            }
          ]
        }
      : {}),
    inventory: [
      {
        name: 'goods_in',
        scope: 'inventory:goods_in',
        title: vm.$t('nav.main.items.inventory.goods_in'),
        children: [
          {
            name: 'create',
            scope: 'inventory:goods_in:create',
            title: vm.$t('common.actions.create')
          }
        ]
      },
      {
        name: 'goods_out',
        scope: 'inventory:goods_out',
        title: vm.$t('nav.main.items.inventory.goods_out'),
        children: [
          {
            name: 'create',
            scope: 'inventory:goods_out:create',
            title: vm.$t('common.actions.create')
          }
        ]
      },
      {
        name: 'relocation',
        scope: 'inventory:relocation',
        title: vm.$t('nav.main.items.inventory.relocation'),
        children: [
          {
            name: 'create',
            scope: 'inventory:relocation:create',
            title: vm.$t('common.actions.create')
          }
        ]
      },
      {
        name: 'stock_movements',
        scope: 'inventory:stock_movements',
        title: vm.$t('pages.stock_movements.title'),
        children: [
          {
            name: 'create',
            scope: 'inventory:stock_movements:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'export',
            scope: 'inventory:stock_movements:export',
            title: vm.$t('common.actions.export')
          }
        ]
      },
      {
        name: 'reasons',
        scope: 'inventory:reasons',
        title: vm.$t('pages.reasons.title'),
        children: [
          {
            name: 'read',
            scope: 'inventory:reasons:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'inventory:reasons:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'inventory:reasons:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'inventory:reasons:delete',
            title: vm.$t('common.actions.delete')
          }
        ]
      },
      {
        name: 'processes',
        scope: 'inventory:processes',
        title: vm.$t('pages.processes.title'),
        children: [
          {
            name: 'read',
            scope: 'inventory:processes:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'inventory:processes:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'inventory:processes:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'inventory:processes:delete',
            title: vm.$t('common.actions.delete')
          },
          {
            name: 'export',
            scope: 'inventory:processes:export',
            title: vm.$t('common.actions.export')
          }
        ]
      },
      {
        name: 'stock_takings',
        scope: 'inventory:stock_takings',
        title: vm.$t('pages.stock_takings.title'),
        children: [
          {
            name: 'read',
            scope: 'inventory:stock_takings:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'inventory:stock_takings:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'inventory:stock_takings:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'inventory:stock_takings:delete',
            title: vm.$t('common.actions.delete')
          },
          {
            name: 'export',
            scope: 'inventory:stock_takings:export',
            title: vm.$t('common.actions.export')
          }
        ]
      }
    ],
    accounting: [
      {
        name: 'expense_accounts',
        scope: 'accounting:expense_accounts',
        title: vm.$t('pages.expense_accounts.title'),
        children: [
          {
            name: 'read',
            scope: 'accounting:expense_accounts:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'accounting:expense_accounts:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'accounting:expense_accounts:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'accounting:expense_accounts:delete',
            title: vm.$t('common.actions.delete')
          }
        ]
      },
      {
        name: 'taxes',
        scope: 'accounting:taxes',
        title: vm.$t('pages.taxes.title'),
        children: [
          {
            name: 'read',
            scope: 'accounting:taxes:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'accounting:taxes:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'accounting:taxes:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'accounting:taxes:delete',
            title: vm.$t('common.actions.delete')
          }
        ]
      },
      {
        name: 'revenue_accounts',
        scope: 'accounting:revenue_accounts',
        title: vm.$t('nav.main.items.accounting.accounts'),
        children: [
          {
            name: 'read',
            scope: 'accounting:revenue_accounts:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'accounting:revenue_accounts:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'accounting:revenue_accounts:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'accounting:revenue_accounts:delete',
            title: vm.$t('common.actions.delete')
          }
        ]
      },
      {
        name: 'payment_options',
        scope: 'accounting:payment_options',
        title: vm.$t('pages.payment_options.title'),
        children: [
          {
            name: 'read',
            scope: 'accounting:payment_options:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'accounting:payment_options:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'accounting:payment_options:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'accounting:payment_options:delete',
            title: vm.$t('common.actions.delete')
          }
        ]
      },
      {
        name: 'safe_management',
        scope: 'accounting:safe_management',
        title: vm.$t('pages.safe_management.title'),
        children: [
          {
            name: 'read',
            scope: 'accounting:safe_management:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create_safe',
            scope: 'accounting:safe_management:create_safe',
            title: vm.$t('pages.safe_management.create_safe')
          },
          {
            name: 'create_safe_booking',
            scope: 'accounting:safe_management:create_safe_booking',
            title: vm.$t('pages.safe_management.create_safe_booking')
          },
          {
            name: 'update',
            scope: 'accounting:safe_management:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'accounting:safe_management:delete',
            title: vm.$t('common.actions.delete')
          }
        ]
      }
    ],
    products: [
      {
        name: 'products',
        scope: 'products',
        title: vm.$t('pages.products.title'),
        children: [
          {
            name: 'read',
            scope: 'products:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'products:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'create_stock_booking',
            scope: 'products:create_stock_booking',
            title: vm.$t('pages.products.all.create_stock_booking')
          },
          {
            name: 'update',
            scope: 'products:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'products:delete',
            title: vm.$t('common.actions.delete')
          },
          {
            name: 'bulk_import',
            scope: 'products:bulk_import',
            title: vm.$t('pages.products.all.bulk_import')
          }
        ]
      },
      {
        name: 'product_groups',
        scope: 'products:product_groups',
        title: vm.$t('pages.product_groups.title'),
        children: [
          {
            name: 'read',
            scope: 'products:product_groups:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'products:product_groups:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'products:product_groups:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'products:product_groups:delete',
            title: vm.$t('common.actions.delete')
          }
        ]
      },
      {
        name: 'product_templates',
        scope: 'products:product_templates',
        title: vm.$t('pages.product_templates.title'),
        children: [
          {
            name: 'read',
            scope: 'products:product_templates:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'products:product_templates:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'products:product_templates:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'products:product_templates:delete',
            title: vm.$t('common.actions.delete')
          }
        ]
      },
      {
        name: 'service_questions',
        scope: 'products:service_questions',
        title: vm.$t('pages.product_service_questions.title'),
        children: [
          {
            name: 'read',
            scope: 'products:service_questions:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'products:service_questions:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'products:service_questions:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'products:service_questions:delete',
            title: vm.$t('common.actions.delete')
          }
        ]
      },
      {
        name: 'discounts',
        scope: 'products:discounts',
        title: vm.$t('pages.discounts.title'),
        children: [
          {
            name: 'read',
            scope: 'products:discounts:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'products:discounts:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'products:discounts:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'products:discounts:delete',
            title: vm.$t('common.actions.delete')
          }
        ]
      },
      {
        name: 'pricebooks',
        scope: 'products:pricebooks',
        title: vm.$t('pages.pricebooks.title'),
        children: [
          {
            name: 'read',
            scope: 'products:pricebooks:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'products:pricebooks:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'products:pricebooks:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'products:pricebooks:delete',
            title: vm.$t('common.actions.delete')
          }
        ]
      },
      {
        name: 'promotions',
        scope: 'products:promotions',
        title: vm.$t('pages.promotions.title'),
        children: [
          {
            name: 'read',
            scope: 'products:promotions:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'products:promotions:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'products:promotions:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'products:promotions:delete',
            title: vm.$t('common.actions.delete')
          }
        ]
      },
      {
        name: 'tags',
        scope: 'products:tags',
        title: vm.$t('pages.tags.title'),
        children: [
          {
            name: 'read',
            scope: 'products:tags:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'products:tags:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'products:tags:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'products:tags:delete',
            title: vm.$t('common.actions.delete')
          }
        ]
      }
    ],
    loyalty: [
      {
        name: 'vouchers',
        scope: 'loyalty',
        title: vm.$t('pages.vouchers.title'),
        children: [
          {
            name: 'read',
            scope: 'loyalty:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'loyalty:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'loyalty:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'loyalty:delete',
            title: vm.$t('common.actions.delete')
          },
          {
            name: 'export',
            scope: 'loyalty:export',
            title: vm.$t('common.actions.export')
          }
        ]
      },
      {
        name: 'voucher_systems',
        scope: 'loyalty:voucher_systems',
        title: vm.$t('pages.voucher_systems.title'),
        children: [
          {
            name: 'read',
            scope: 'loyalty:voucher_systems:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'loyalty:voucher_systems:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'loyalty:voucher_systems:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'loyalty:voucher_systems:delete',
            title: vm.$t('common.actions.delete')
          }
        ]
      },
      {
        name: 'abocard_systems',
        scope: 'loyalty:abocard_systems',
        title: vm.$t('pages.abocard_systems.title'),
        children: [
          {
            name: 'read',
            scope: 'loyalty:abocard_systems:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'loyalty:abocard_systems:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'loyalty:abocard_systems:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'loyalty:abocard_systems:delete',
            title: vm.$t('common.actions.delete')
          }
        ]
      },
      {
        name: 'logs',
        scope: 'loyalty:logs',
        title: vm.$t('nav.main.items.loyalty.logs')
      }
    ],
    resources: [
      {
        name: 'branches',
        scope: 'resources:branches',
        title: vm.$t('pages.branches.title'),
        children: [
          {
            name: 'read',
            scope: 'resources:branches:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'resources:branches:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'resources:branches:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'resources:branches:delete',
            title: vm.$t('common.actions.delete')
          }
        ]
      },
      {
        name: 'branch_groups',
        scope: 'resources:branch_groups',
        title: vm.$t('pages.branch_groups.title'),
        children: [
          {
            name: 'read',
            scope: 'resources:branch_groups:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'resources:branch_groups:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'resources:branch_groups:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'resources:branch_groups:delete',
            title: vm.$t('common.actions.delete')
          }
        ]
      },
      {
        name: 'warehouses',
        scope: 'resources:warehouses',
        title: vm.$t('pages.warehouses.title'),
        children: [
          {
            name: 'read',
            scope: 'resources:warehouses:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'resources:warehouses:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'resources:warehouses:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'resources:warehouses:delete',
            title: vm.$t('common.actions.delete')
          }
        ]
      },
      {
        name: 'registers',
        scope: 'resources:registers',
        title: vm.$t('pages.registers.title'),
        children: [
          {
            name: 'read',
            scope: 'resources:registers:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'update',
            scope: 'resources:registers:update',
            title: vm.$t('common.actions.update')
          }
        ]
      },
      {
        name: 'licenses',
        scope: 'resources:licenses',
        title: vm.$t('nav.main.items.resources.licenses'),
        children: [
          {
            name: 'read',
            scope: 'resources:licenses:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'resources:licenses:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'resources:licenses:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'resources:licenses:delete',
            title: vm.$t('common.actions.delete')
          }
        ]
      },
      {
        name: 'devices',
        scope: 'resources:devices',
        title: vm.$t('pages.devices.title'),
        children: [
          {
            name: 'read',
            scope: 'resources:devices:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'update',
            scope: 'resources:devices:update',
            title: vm.$t('common.actions.update')
          }
        ]
      },
      {
        name: 'device_groups',
        scope: 'resources:device_groups',
        title: vm.$t('pages.device_groups.title'),
        children: [
          {
            name: 'read',
            scope: 'resources:device_groups:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'resources:device_groups:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'resources:device_groups:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'resources:device_groups:delete',
            title: vm.$t('common.actions.delete')
          }
        ]
      }
    ],
    customers: [
      {
        name: 'customers',
        scope: 'customers',
        title: vm.$t('pages.customers.title'),
        children: [
          {
            name: 'read',
            scope: 'customers:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'customers:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'customers:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'customers:delete',
            title: vm.$t('common.actions.delete')
          }
        ]
      }
    ],
    ...getSuppliersPermissions(vm),
    documents: [
      {
        name: 'documents',
        scope: 'documents',
        title: vm.$t('nav.main.items.documents.title'),
        children: [
          {
            name: 'read',
            scope: 'documents:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'send',
            scope: 'documents:send',
            title: vm.$t('common.actions.send')
          },
          {
            name: 'export',
            scope: 'documents:export',
            title: vm.$t('common.actions.export')
          }
        ]
      }
    ],
    staff: [
      {
        name: 'staff',
        scope: 'staff',
        title: vm.$t('pages.staff.title'),
        children: [
          {
            name: 'read',
            scope: 'staff:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'staff:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'staff:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'staff:delete',
            title: vm.$t('common.actions.delete')
          }
        ]
      },
      {
        name: 'staff_groups',
        scope: 'staff:groups',
        title: vm.$t('nav.main.items.staff.staff-groups'),
        children: [
          {
            name: 'read',
            scope: 'staff:groups:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'staff:groups:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'staff:groups:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'staff:groups:delete',
            title: vm.$t('common.actions.delete')
          }
        ]
      }
    ],
    ...(vm.$isFeatureEnabled('reservations')
      ? {
          reservations: [
            {
              name: 'services',
              scope: 'reservations:services',
              title: vm.$t('nav.main.items.reservations.services'),
              children: [
                {
                  name: 'read',
                  scope: 'reservations:services:read',
                  title: vm.$t('common.actions.read')
                },
                {
                  name: 'create',
                  scope: 'reservations:services:create',
                  title: vm.$t('common.actions.create')
                },
                {
                  name: 'update',
                  scope: 'reservations:services:update',
                  title: vm.$t('common.actions.update')
                },
                {
                  name: 'delete',
                  scope: 'reservations:services:delete',
                  title: vm.$t('common.actions.delete')
                }
              ]
            },
            {
              name: 'service_categories',
              scope: 'reservations:service_categories',
              title: vm.$t('nav.main.items.reservations.service_category'),
              children: [
                {
                  name: 'read',
                  scope: 'reservations:service_categories:read',
                  title: vm.$t('common.actions.read')
                },
                {
                  name: 'create',
                  scope: 'reservations:service_categories:create',
                  title: vm.$t('common.actions.create')
                },
                {
                  name: 'update',
                  scope: 'reservations:service_categories:update',
                  title: vm.$t('common.actions.update')
                },
                {
                  name: 'delete',
                  scope: 'reservations:service_categories:delete',
                  title: vm.$t('common.actions.delete')
                }
              ]
            }
          ]
        }
      : {}),
    timetracking: [
      {
        name: 'timetracking',
        scope: 'timetracking',
        title: vm.$t('nav.main.items.timetracking.title'),
        children: [
          {
            name: 'read',
            scope: 'timetracking:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'timetracking:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'timetracking:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'timetracking:delete',
            title: vm.$t('common.actions.delete')
          }
        ]
      }
    ],
    utilities: [
      {
        name: 'document_designer',
        scope: 'utilities:document_designer',
        title: vm.$t('nav.main.items.utilities.document_designer'),
        children: [
          {
            name: 'read',
            scope: 'utilities:document_designer:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'update',
            scope: 'utilities:document_designer:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'utilities:document_designer:delete',
            title: vm.$t('common.actions.delete')
          }
        ]
      },
      {
        name: 'audits',
        scope: 'utilities:audits',
        title: vm.$t('nav.main.items.utilities.audits')
      },
      {
        name: 'cms',
        scope: 'utilities:cms',
        title: vm.$t('nav.main.items.utilities.cms.title'),
        children: [
          {
            name: 'read',
            scope: 'utilities:cms:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'utilities:cms:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'utilities:cms:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'utilities:cms:delete',
            title: vm.$t('common.actions.delete')
          }
        ]
      },
      {
        name: 'custom_dashboard',
        scope: 'utilities:custom_dashboard',
        title: vm.$t('nav.main.items.utilities.custom_dashboards'),
        children: [
          {
            name: 'read',
            scope: 'utilities:custom_dashboard:read',
            title: vm.$t('common.actions.read')
          }
        ]
      },
      {
        name: 'notifications',
        scope: 'utilities:notifications',
        title: vm.$t('nav.main.items.utilities.notifications'),
        children: [
          {
            name: 'create',
            scope: 'utilities:notifications:create',
            title: vm.$t('common.actions.create')
          }
        ]
      },
      {
        name: 'favourites',
        scope: 'utilities:favourites',
        title: vm.$t('nav.main.items.utilities.favourites'),
        children: [
          {
            name: 'read',
            scope: 'utilities:favourites:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'utilities:favourites:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'utilities:favourites:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'utilities:favourites:delete',
            title: vm.$t('common.actions.delete')
          }
        ]
      },
      ...getExportHistoryPermissions(vm)
    ],
    settings: [
      {
        name: 'settings-users',
        scope: 'settings:users',
        title: vm.$t('nav.main.items.settings.users'),
        children: [
          {
            name: 'read',
            scope: 'settings:users:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'settings:users:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'settings:users:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'settings:users:delete',
            title: vm.$t('common.actions.delete')
          }
        ]
      },
      {
        name: 'settings-dashboard',
        scope: 'settings:dashboard',
        title: vm.$t('nav.main.items.settings.general'),
        children: [
          {
            name: 'read',
            scope: 'settings:dashboard:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'update',
            scope: 'settings:dashboard:update',
            title: vm.$t('common.actions.update')
          }
        ]
      },
      {
        name: 'settings-pos',
        scope: 'settings:pos',
        title: vm.$t('nav.main.items.settings.pos'),
        children: [
          {
            name: 'read',
            scope: 'settings:pos:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'update',
            scope: 'settings:pos:update',
            title: vm.$t('common.actions.update')
          }
        ]
      }
    ]
  }
}

function getSuppliersPermissions(vm) {
  const suppliersPermissions = {
    suppliers: [
      {
        name: 'suppliers',
        scope: 'supplier_management:suppliers',
        title: vm.$t('nav.main.items.supplier.manager'),
        children: [
          {
            name: 'read',
            scope: 'supplier_management:suppliers:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'supplier_management:suppliers:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'supplier_management:suppliers:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'delete',
            scope: 'supplier_management:suppliers:delete',
            title: vm.$t('common.actions.delete')
          },
          {
            name: 'export',
            scope: 'supplier_management:suppliers:export',
            title: vm.$t('common.actions.export')
          }
        ]
      },
      {
        name: 'purchase_orders',
        scope: 'supplier_management:purchase_orders',
        title: vm.$t('nav.main.items.supplier.purchase_orders'),
        children: [
          {
            name: 'read',
            scope: 'supplier_management:purchase_orders:read',
            title: vm.$t('common.actions.read')
          },
          {
            name: 'create',
            scope: 'supplier_management:purchase_orders:create',
            title: vm.$t('common.actions.create')
          },
          {
            name: 'update',
            scope: 'supplier_management:purchase_orders:update',
            title: vm.$t('common.actions.update')
          },
          {
            name: 'export',
            scope: 'supplier_management:purchase_orders:export',
            title: vm.$t('common.actions.export')
          },
          {
            name: 'send',
            scope: 'supplier_management:purchase_orders:send',
            title: vm.$t('common.actions.send')
          }
        ]
      }
    ]
  }
  return safeGet(vm, 'featureConfig.suppliers', false)
    ? suppliersPermissions
    : {}
}
