export const getExportHistoryPermissions = (vm) => {
  return [
    {
      name: 'export-history',
      scope: 'utilities:export_history',
      title: vm.$t('nav.main.items.utilities.export_history'),
      children: [
        {
          name: 'read',
          scope: 'utilities:export_history:read',
          title: vm.$t('common.actions.read')
        },
        {
          name: 'create',
          scope: 'utilities:export_history:create',
          title: vm.$t('common.actions.create')
        },
        {
          name: 'update',
          scope: 'utilities:export_history:update',
          title: vm.$t('common.actions.update')
        },
        {
          name: 'delete',
          scope: 'utilities:export_history:delete',
          title: vm.$t('common.actions.delete')
        }
      ]
    }
  ]
}
