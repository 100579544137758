//Use this scope prefix for local scopes
//that will not be send to the API
export const TEMP_SCOPE_PREFIX = 'temporary:scope'

export function getInitialTemplate() {
  return {
    scopes: [],
    name: ''
  }
}

export function getSelectOptions(permissions, selectedPermissions = []) {
  const options = { modelValue: false, indeterminate: false } //if no permissions selected
  if (isAllSelected(permissions, selectedPermissions)) {
    //if all permissions selected
    options.modelValue = true
  } else if (isSomeSelected(permissions, selectedPermissions)) {
    //if Some permissions selected
    options.indeterminate = true
  }
  return options
}

export function deepFlat(permission) {
  const scopes = [permission.scope]
  if (permission.children) {
    permission.children.forEach((child) => {
      scopes.push(...deepFlat(child))
    })
  }
  return scopes.filter((scope) => !scope?.startsWith(TEMP_SCOPE_PREFIX))
}

export function flatPermissions(permissions) {
  //Array
  if (Array.isArray(permissions)) {
    return permissions.reduce((flatPermissions, permission) => {
      flatPermissions.push(...deepFlat(permission))
      return flatPermissions
    }, [])
  } else {
    //Object
    return deepFlat(permissions)
  }
}

export function isAllSelected(permissions, selectedPermissions = []) {
  //return false if find one item that is not selected
  const scopes = removeScopeFromList(
    flatPermissions(permissions),
    permissions.scope
  )
  return scopes.every((permission) => selectedPermissions.includes(permission))
}

export function isSomeSelected(permissions, selectedPermissions = []) {
  //return true if find one item that is selected
  const scopes = removeScopeFromList(
    flatPermissions(permissions),
    permissions.scope
  )
  return scopes.some((permission) => selectedPermissions.includes(permission))
}

function removeScopeFromList(list, scope) {
  //remove scope item from list
  const scopeIndex = list.indexOf(scope)
  list.splice(scopeIndex, 1)
  return list
}
